import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Input,
  Span,
  Textarea,
  Select,
  MessageParagraph,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "Base64 Encoder", link: "/base64-encoder/" },
]
const seeAlsoArray = [
  "/base64-decoder",
  "/base32-decoder",
  "/base32-encoder",
  "/binary-encoder",
]

function FilesBase64Encoder(props) {
  let [input, setinput] = useState("")
  let [base64, setbase64] = useState("")
  let [errorElemnet, seterrorElemnet] = useState("")
  let fileName = useRef("")
  let extentionOption = useRef("")

  function inputC(e) {
    setinput(e.target.value)
    if (e.target.value !== "") {
      var binaryData = e.target.value
      var base64String = window.btoa(binaryData)
      setbase64(base64String)
    }
  }

  function handleFileSelect(evt) {
    setinput("")
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var f = evt.target.files[0]
      var reader = new FileReader()
      reader.onload = (function () {
        return function (e) {
          var binaryData = e.target.result
          var base64String = window.btoa(binaryData)
          setbase64(base64String)
          seterrorElemnet(
            "File converted to base64 successfuly!\nCheck in Textarea"
          )
        }
      })(f)
      reader.readAsBinaryString(f)
    } else {
      seterrorElemnet("The File APIs are not fully supported in this browser.")
    }
  }

  function downloadObjectAsJson(exportName) {
    let myfileName = fileName.current.value
    if (myfileName === "") {
      exportName = "unitpedia-base64"
    } else {
      exportName = myfileName
    }
    if (base64 === "") {
      seterrorElemnet("Please make the conversion before download")
    } else {
      let extension = extentionOption.current.value
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(base64)
      var downloadAnchorNode = document.createElement("a")
      downloadAnchorNode.setAttribute("href", dataStr)
      downloadAnchorNode.setAttribute("download", exportName + extension)
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Base64 File Encode, Files to base64"
        description="Base64 File Encode, Files to base64, Simple file encoder to base64 online and fast, first upload the file Supported all image, audio, video, zip, folders, and many formats, and Base64 will appear in the text area some supported formats: jpg, jpeg, png, gif, BMP, ico, SVG, mp3, mp4, WMV, Opus, MP3, Vorbis, Musepack, AAC, ATRAC, mp4, m4a, m4v, f4v, f4a, m4b, m4r, f4b, MOV, 3gp, Flv."
        keywords={[
          "base64 encoder file,file to base64,files encoder,audio encoder base64, video encoder base64,transfer Files To Base64 , zip encoder, all files to base64, songs to base64, mp4 to base64, 3gp to base64, zip to base64, wmv to base64, mp3 to base64,online img encoder, base64 encoder image,img encoder decoder.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Base64 Encoder</Title>
        <SubTitle>Transfer Files To Base64</SubTitle>

        <div className="mb-2">
          <input
            aria-label="upload"
            type="file"
            id="files"
            onChange={handleFileSelect}
          />
          <span>Choose a file…</span>
        </div>
        <Span>Input Text to Encode Or Upload a file</Span>

        <Textarea value={input} onChange={inputC} />

        <MessageParagraph color="red">{errorElemnet}</MessageParagraph>
        <div>
          <Span>File Output Base64</Span>
          <Textarea value={base64} readOnly />
        </div>
        <br />
        <div className="mb-2">
          <Span>Filename </Span>

          <Input width="150px" type="text" ref={fileName} />
        </div>
        <div className="mb-2">
          <Span>Extension </Span>

          <Select maxWidth="150px">
            <select name="unitSelector" ref={extentionOption}>
              <option value=".txt">.txt</option>
              <option value=".json">.json</option>
              <option value=".doc">.doc</option>
              <option value=".docx">.docx</option>
              <option value=".rtf">.rtf</option>
              <option value=".xls">.xls</option>
              <option value=".xlsx">.xlsx</option>
              <option value=".xlr">.xlr</option>
              <option value=".csv">.csv</option>
              <option value=".css">.css</option>
              <option value=".htm">.htm</option>
              <option value=".html">.html</option>
            </select>
          </Select>
        </div>
        <Button
          onClick={downloadObjectAsJson}
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
        >
          Download
        </Button>
        <br />

        <p>
          <br />
          supported formats: mp3, mp4, wmv, Opus, MP3, Vorbis, Musepack, AAC,
          ATRAC, mp4, m4a, m4v, f4b, f4v, f4a, m4b, m4r, mov, 3gp,flv, jpg,
          jpeg, png, gif, bmp, ico, svg.
        </p>
        <br />
        <h4>Base64</h4>
        <p>
          Base64 is a group of binary data in an ASCII string format by
          translating it into a radix-64 representation.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default FilesBase64Encoder
